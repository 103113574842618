import React, { useEffect } from "react";
import { Layout, Button, Row, Card, Col, Typography, Form } from "antd";
import logo from "../assets/images/logo.svg";
import BaseInput from "../components/form/BaseInput";
import PasswordInput from "../components/form/PasswordInput";
import { LockOutlined, MailOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { loginRequest } from "../redux/authSlice";
import { useNavigate } from "react-router-dom";
import { STATUSES } from "../config/constants/defaultValues";
import { SignInWrapped } from "./style";
const { Title } = Typography;
const { Header, Content } = Layout;

function SignIn() {
  const { status, userData } = useSelector((state) => state.auth);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onFinish = (values) => {
    dispatch(loginRequest({ values }))
      .unwrap()
      .then(() => {
        navigate("/dashboard");
      });
  };

  useEffect(() => {
    if (userData) {
      navigate("/dashboard");
    }
  }, []);

  return (
    <SignInWrapped>
      <Layout className="layout-default layout-signin layout-signin-bg ">
        <Content className="signin">
          <Row gutter={[24, 0]} justify="space-around">
            <Col
              align="middle"
              xs={{ span: 24 }}
              xl={{ span: 8 }}
              lg={{ span: 12 }}
              md={{ span: 16 }}
            >
              <Card className="signin-card">
                <div className="header-col header-brand">
                  <a href="/">
                    <img src={logo} alt="" />
                  </a>
                </div>
                <Title className="mb-15 signin-title">Sign in</Title>
                <Form
                  onFinish={onFinish}
                  layout="vertical"
                  className="row-col align-signin"
                  autoComplete="off"
                  
                  initialValues={{
                    remember: true,

                  }}
                >
                  <BaseInput
                    name="email"
                    type="email"
                    typeMessage="Please enter a valid email."
                    inputIcon={<MailOutlined />}
                    placeText="Email Address"
                    sizes="large"
                    maxLength={254}
                    req={true}
                  />
                  <div style={{ height: "10px" }}></div>
                  <PasswordInput
                    name="password"
                    inputIcon={<LockOutlined />}
                    placeText="Password"
                  />

                  <Form.Item className="text-align-end mb-0">
                    <Button
                      className="btn-style"
                      htmlType="submit"
                      style={{ width: "25%" }}
                      loading={status === STATUSES.LOADING}
                    >
                      Log in
                    </Button>
                  </Form.Item>
                </Form>
              </Card>
            </Col>
          </Row>
        </Content>
      </Layout>
    </SignInWrapped>
  );
}

export default SignIn;
