import { SearchOutlined, UserOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Form,
  Pagination,
  Row,
  Space,
  Table,
  Typography,
} from "antd";
import Search from "antd/lib/input/Search";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as XLSX from "xlsx";
import RangePickers from "../../components/DatePicker/RangePickers";
import JobApplicantDetailModal from "../../components/modal/JobAppliedUserDetailModel";
import { errorNotification } from "../../components/notifications/alertNotification";
import Selects from "../../components/select/Selects";
import Loading from "../../components/shared/GeneralComponents";
import { STATUSES } from "../../config/constants/defaultValues";
import {
  getDisciplineListRequest,
  getHospitalTypeListRequest,
  getLocationListRequest,
  getStateListRequest,
} from "../../redux/appDataSlice";
import {
  getJobAppliedExportListRequest,
  getJobAppliedListRequest,
} from "../../redux/jobAppliedSlice";
import { JobRequestStyle } from "../style";
import { SystemConfigurationWrapped } from "../systemConfiguration/style";
const { Text } = Typography;

const JobAppliedList = () => {
  const dispatch = useDispatch();
  const { status, jobAppliedData } = useSelector((state) => state.jobApplied);
  const {
    DisciplineData,
    DisciplineStatus,
    StateData,
    StateStatus,
    HospitalTypeData,
    HospitalTypeStatus,
    LocationData,
    LocationStatus,
  } = useSelector((state) => state.appData);
  const [search, setSearch] = useState();

  const [exportLoading, setExportLoading] = useState(false);
  const [selectedValues, setSelectedValues] = useState(null);
  const [form] = Form.useForm();

  const [modalData, setModelData] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    dispatch(getJobAppliedListRequest({}));
  }, [dispatch]);
  console.log("jobAppliedData", jobAppliedData);
  const paginationOnChange = (data, pageSize) => {
    dispatch(
      getJobAppliedListRequest({
        generalSearch: search,
        page: data,
        pageSize: pageSize,
        discipline: selectedValues?.discipline?.id,
        state: selectedValues?.state?.id,
        hospitalType: selectedValues?.hospitalType?.id,
        location: selectedValues?.location?.id,
        dateRange: selectedValues?.dateRange,
      })
    );
  };

  const onSearch = (generalSearch) => {
    setSearch(generalSearch);
    dispatch(
      getJobAppliedListRequest({
        generalSearch,
        page: 1,
        discipline: selectedValues?.discipline?.id,
        state: selectedValues?.state?.id,
        hospitalType: selectedValues?.hospitalType?.id,
        location: selectedValues?.location?.id,
        dateRange: selectedValues?.dateRange,
      })
    );
  };

  const onDataChange = ({
    discipline,
    state,
    hospitalType,
    location,
    dateRange,
  }) => {
    // Copy the existing selectedValues into a new object
    const newSelectedValues = { ...selectedValues };

    // Update only the  property in the new object
    if (discipline !== undefined) {
      newSelectedValues.discipline = discipline;
    }
    if (state !== undefined) {
      console.log("changeinState", state);
      newSelectedValues.state = state;
      newSelectedValues.hospitalType = null;
      newSelectedValues.location = null;
      form.resetFields(["hospital_id", "location_id"]);
    }

    if (hospitalType !== undefined) {
      newSelectedValues.hospitalType = hospitalType;
      newSelectedValues.location = null;
      form.resetFields(["location_id"]);
    }

    if (location !== undefined) {
      newSelectedValues.location = location;
    }

    if (dateRange !== undefined) {
      newSelectedValues.dateRange = dateRange;
    }

    setSelectedValues(newSelectedValues);
    dispatch(
      getJobAppliedListRequest({
        generalSearch: search,
        discipline: newSelectedValues?.discipline?.id,
        state: newSelectedValues?.state?.id,
        hospitalType: newSelectedValues?.hospitalType?.id,
        location: newSelectedValues?.location?.id,
        dateRange: newSelectedValues?.dateRange,
      })
    );
  };

  const clearSelect = () => {
    form.resetFields([
      "discipline_id",
      "state_id",
      "hospital_id",
      "location_id",
      "Date_Posted",
    ]);
    setSearch(null);
    onDataChange({
      discipline: null,
      state: null,
      hospitalType: null,
      location: null,
      dateRange: null,
    });
  };

  const exportToExcel = async () => {
    setExportLoading(true);
    try {
      const data = await dispatch(
        getJobAppliedExportListRequest({
          generalSearch: search,
          discipline: selectedValues?.discipline?.id,
          state: selectedValues?.state?.id,
          hospitalType: selectedValues?.hospitalType?.id,
          location: selectedValues?.location?.id,
          dateRange: selectedValues?.dateRange,
        })
      );

      let filtered = [...data.payload];
      console.log("filtered", filtered);
      const worksheet = XLSX.utils.json_to_sheet(
        filtered.map((record) => ({
          "First Name": record?.first_name,
          "Last Name": record?.last_name,
          Discipline: record?.job?.discipline?.name,
          email: record?.email,
          "Phone Number": record?.phone,
          Location: record?.job?.location_detail?.name,
          Salary: "$" + record?.job?.salary,
          salary_period: record?.job?.salary_period,
          State: record?.job?.state?.name,
          "Hospital Type": record?.job?.hospital_type.name,
          "Applied Date": record?.updated_at,
          "User Note": record?.note,
          resume: record?.resume,
          licenses: record?.job_apply_licenses
            .map((license) => license?.user_license?.license)
            .join(",\n "),
        }))
      );

      const currentDateTimeString = moment(new Date()).format(
        "MM-DD-YYYY-hh:mm-A"
      );
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      XLSX.writeFile(
        workbook,
        `imperial-Job-Applied-DataSheet-${currentDateTimeString}.xlsx`
      );
    } catch (error) {
      errorNotification("Error while fetching or processing data:", error);
    } finally {
      setExportLoading(false); // Ensure that loading indicator is turned off, even in case of an error
    }
  };

  const showModal = (record) => {
    setModelData(record);
    setIsModalOpen(true);
  };
  const columns = [
    {
      title: "#",
      dataIndex: "id",
      width: "4%",
    },
    {
      title: "First Name",
      dataIndex: "first_name",
      width: "12%",
    },
    {
      title: "Last Name",
      dataIndex: "last_name",
      // width: "10%",
    },
    {
      title: "Discipline",
      dataIndex: "job",
      ellipsis: true,
      // width: "20%",
      render: (text) => <Text strong>{text?.discipline?.name}</Text>,
    },
    {
      title: "Email",
      dataIndex: "email",
      ellipsis: true,
      width: "20%",
    },
    {
      title: "Phone Number",
      dataIndex: "phone",
      ellipsis: true,
      width: "12%",
    },

    {
      title: "Location",
      dataIndex: "job",
      ellipsis: true,
      // width: "25%",
      render: (text) => <span>{text?.location_detail?.name}</span>,
    },

    {
      title: "Applied Data",
      dataIndex: "updated_at",
      ellipsis: true,
      // render: (text) => moment(text).format('MM-DD-YYYY'),
      sorter: (a, b) => {
        // Convert the date strings to Date objects for comparison
        const dateA = new Date(a.updated_at);
        const dateB = new Date(b.updated_at);

        return dateA - dateB; // To sort in ascending order
      },
    },
    {
      title: "Actions",
      dataIndex: "actions",
      render: (text, record) => (
        <Space>
          <span
            style={{
              color: "#372354", // Set the icon color to blue
              cursor: "pointer", // Show hand cursor on hover
            }}
            className="job_view"
            onClick={() => showModal(record)}
          >
            <UserOutlined />
          </span>
          {/* <Link
            className='view_btn'
            style={{ marginLeft: "10px" }}
            to={`/job-list/job-edit/${record?.id ?? ""}`}
          >
            <i class='bi bi-pencil'></i>
          </Link> */}
        </Space>
      ),
    },
  ];

  const getDisciplineData = () => {
    if (DisciplineStatus === STATUSES.IDLE) {
      dispatch(getDisciplineListRequest());
    }
  };
  const getStateData = () => {
    if (StateStatus === STATUSES.IDLE) {
      dispatch(getStateListRequest());
    }
  };
  const getHospitalTypeData = () => {
    if (selectedValues?.state?.id) {
      dispatch(getHospitalTypeListRequest({ state: selectedValues.state.id }));
    } else {
      errorNotification("Please Select State First");
    }
  };
  const getLocationData = () => {
    if (selectedValues?.state?.id && selectedValues?.hospitalType?.id) {
      dispatch(
        getLocationListRequest({
          state: selectedValues.state.id,
          hospitalType: selectedValues?.hospitalType?.id,
        })
      );
    } else {
      errorNotification("Please Select State and Hospital-Type First");
    }
  };

  return (
    <>
      <JobRequestStyle>
        <div className="layout-content">
          <Row className="rowgap-vbox" gutter={[24, 0]}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} className="mb-24">
              <div className="row pb-2 pb-md-5">
                <div className="col-sm-9">
                  <div className="heading">Jobs Applied List</div>
                </div>
              </div>
              <Form form={form}>
                <div className="row pb-2 pb-sm-5">
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                    <Selects
                      name="discipline_id"
                      placeText="Select Discipline"
                      req={false}
                      labelText="Select Discipline"
                      notFoundContent={
                        DisciplineStatus === STATUSES.LOADING ? (
                          <Loading height={"30vh"} />
                        ) : null
                      }
                      onSelect={(_, data) => {
                        onDataChange({
                          discipline: { id: data.value, name: data.children },
                        });
                      }}
                      onClear={() => {
                        onDataChange({ discipline: null });
                      }}
                      onClick={getDisciplineData}
                      options={DisciplineData?.collection_list ?? []}
                    />
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                    <Selects
                      name="state_id"
                      labelText="State"
                      placeText="State"
                      req={false}
                      notFoundContent={
                        StateStatus === STATUSES.LOADING ? (
                          <Loading height={"30vh"} />
                        ) : null
                      }
                      onSelect={(_, data) => {
                        onDataChange({
                          state: { id: data.value, name: data.children },
                        });
                      }}
                      onClear={() => {
                        onDataChange({ state: null });
                      }}
                      onClick={getStateData}
                      options={StateData?.collection_list ?? []}
                    />
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                    <Selects
                      name="hospital_id"
                      labelText="Hospital Type"
                      placeText="Hospital Type"
                      req={false}
                      disabled={selectedValues?.state != null ? false : true}
                      value={
                        selectedValues?.hospitalType == null
                          ? undefined
                          : selectedValues.hospitalType
                      }
                      notFoundContent={
                        HospitalTypeStatus === STATUSES.LOADING ? (
                          <Loading height={"30vh"} />
                        ) : null
                      }
                      onSelect={(_, data) => {
                        onDataChange({
                          hospitalType: { id: data.value, name: data.children },
                        });
                      }}
                      onClear={() => {
                        onDataChange({ hospitalType: null });
                      }}
                      onClick={getHospitalTypeData}
                      options={HospitalTypeData ?? []}
                      tooltipMessage="Please select a state first"
                    />
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                    <Selects
                      name="location_id"
                      labelText="Location"
                      placeText="Location"
                      req={false}
                      disabled={
                        selectedValues?.state != null &&
                        selectedValues?.hospitalType != null
                          ? false
                          : true
                      }
                      notFoundContent={
                        LocationStatus === STATUSES.LOADING ? (
                          <Loading height={"30vh"} />
                        ) : null
                      }
                      value={selectedValues?.location ?? undefined}
                      onSelect={(_, data) => {
                        onDataChange({
                          location: { id: data.value, name: data.children },
                        });
                      }}
                      onClear={() => {
                        onDataChange({ location: null });
                      }}
                      onClick={getLocationData}
                      options={LocationData ?? []}
                      tooltipMessage="Please select a hospital type first"
                    />
                  </div>
                  <div className="col-xl-5 col-lg-5 col-md-5 col-sm-12">
                    <RangePickers
                      name="Date_Posted"
                      req={false}
                      labelText="Date Posted"
                      onChange={(_, dateStrings) => {
                        if (dateStrings[0] === "") {
                          onDataChange({ dateRange: null });
                        } else {
                          onDataChange({
                            dateRange: `${dateStrings[0]} , ${dateStrings[1]}`,
                          });
                        }
                      }}
                      on
                    />
                  </div>
                  <div className="col-sm-6 text-end">
                    <Button className="reject_btn" onClick={clearSelect}>
                      Clear All Filters
                    </Button>
                  </div>
                </div>
              </Form>
              {/* <JobListCard jobsList={jobAppliedData?.data} /> */}
              <SystemConfigurationWrapped>
                <div className="row pb-3">
                  <div className="col-sm-6 p-sm-0">
                    <Search
                      onSearch={onSearch}
                      onChange={(value) => setSearch(value.value)}
                      prefix={<SearchOutlined width={"20px"} />}
                      allowClear
                      placeholder="Search"
                      value={search}
                      style={{
                        marginRight: "15px",
                      }}
                    />
                  </div>
                  <div className="col-sm-6 text-end mt-3 mt-sm-0">
                    <Button
                      className="reject_btn"
                      loading={exportLoading}
                      onClick={exportToExcel}
                    >
                      Export Excel
                    </Button>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-12 p-sm-0">
                    {/* <div className="tab_section"> */}
                    {/* <div className="table-responsive"> */}
                    <Table
                      dataSource={jobAppliedData?.data ?? []}
                      columns={columns}
                      loading={status === STATUSES.LOADING}
                      bordered={true}
                      indentSize={33}
                      pagination={false} // Disable pagination
                      scroll={{ x: 1000 }}
                      // pagination={{
                      //   onChange: onChangePage,
                      //   current: currentPage
                      // }}
                    />
                  </div>
                </div>
                {/* </div> */}
                {/* </div> */}
              </SystemConfigurationWrapped>
            </Col>
          </Row>
        </div>

        {
          <div className="featureBg__border p-2 d-flex justify-content-center w-100 pb-5">
            <Pagination
              current={jobAppliedData?.meta.page ?? 1}
              pageSize={jobAppliedData?.meta.per_page ?? 10}
              total={jobAppliedData?.meta.total}
              showSizeChanger={true}
              onChange={paginationOnChange}
              onShowSizeChange={paginationOnChange}
            />
          </div>
        }
      </JobRequestStyle>
      <JobApplicantDetailModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        modalData={modalData}
      />
    </>
  );
};

export default JobAppliedList;
