import React from "react";
import { Select, Form, Tooltip } from "antd";
import styled from "styled-components";
const SelectInputWrapped = styled.div`
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background: #f7f7f7;
    border: 0.5px solid #b8b8b8;
    border-radius: 5px;
    /* height: 54px; */
  }
  .ant-form-item-label > label {
    font-weight: 400;
    font-size: 16px;
    color: #181818;
  }
  .ant-select:not(.ant-select-customize-input)
    .ant-select-selector
    .ant-select-selection-search-input {
    background: #f7f7f7;
    height: 38px;
    z-index: 99;
  }
  .ant-select-single .ant-select-selector .ant-select-selection-item,
  .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    line-height: 54px;
    z-index: 1;
  }
`;
const { Option } = Select;

const Selects = ({
  placeText,
  labelText,
  options,
  name,
  req,
  loading,
  sizes,
  mode,
  marginBottom,
  onSelect,
  maxLength,
  onClick,
  notFoundContent,
  defaultValue,
  onClear,
  disabled,
  value,
  onChange,
  tooltipMessage = "This Field is disabled", // New prop for tooltip message
}) => {
  return (
    <SelectInputWrapped>
      <Tooltip
        title={disabled ? tooltipMessage : ""} // Show tooltip only if disabled
        placement="topLeft"
      >
        <div>
          <Form.Item
            name={name ?? "name"}
            label={
              labelText != null
                ? req != false
                  ? labelText + " *"
                  : labelText
                : ""
            }
            className={marginBottom ? `${marginBottom} w-100` : "w-100 mb-4"}
            rules={[
              {
                required: req === false ? false : true,
                message: "This field is required!!",
              },
            ]}
            initialValue={defaultValue}
          >
            <Select
              allowClear
              showSearch
              disabled={disabled}
              onClick={onClick}
              loading={loading ?? false}
              placeholder={placeText ?? "Select Here"}
              optionFilterProp="children"
              size={sizes ?? "large"}
              mode={mode ?? ""}
              onSelect={onSelect}
              notFoundContent={notFoundContent}
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
              onChange={onChange}
              value={value}
              onClear={onClear}
              // onFocus={onClick}
              maxLength={maxLength || 50}
              defaultValue={defaultValue}
            >
              {options?.map((optionsData, index) => (
                <Option key={index} value={optionsData.id}>
                  {optionsData?.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </div>
      </Tooltip>
    </SelectInputWrapped>
  );
};

export default Selects;
