import React from "react";
import BaseInput from "../form/BaseInput";
import TextAreas from "../form/TextAreas";
import BannerImageCard from "../shared/bannerImageCard";
import { Checkbox, Form } from "antd";
import BannerImage from "../../assets/images/bannerImage.png";

const SeoSection = ({
    ogImageUrl,
    setOGImageFile,
    setOGImageUrl,
    twitterImageUrl,
    setTwitterImageFile,
    setTwitterImageUrl,
}) => {
    return (
        <div>
            <div className="row my-4">
                <div className="col-xl-10">
                    <div class="accordion mb-0" id="accordionExample">
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="headingOne">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                    <h3 className="mb-0 collapsible font-bold" >SEO Section</h3>
                                </button>
                            </h2>
                            <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    <div class="content row" >
                                        <div className="col-xl-12 mt-3">
                                            <BaseInput
                                                inputIcon={<i className="bi bi-pencil icon_style"></i>}
                                                labelText="Meta Title"
                                                req={false}
                                                name="meta_title"
                                                placeText="Meta Title"
                                            />
                                            <TextAreas
                                                labelText="Meta Description"
                                                req={false}
                                                name="meta_description"
                                                placeText="Meta Description"
                                                maxLength={160}
                                            />
                                            <Form.Item
                                                name="is_canonical" valuePropName="checked" initialValue={false} className="col-xl-5 col-lg-5 col-md-5 col-sm-12">
                                                <Checkbox name="is_canonical">Is Canonical</Checkbox>
                                            </Form.Item>
                                            <BaseInput
                                                inputIcon={<i className="bi bi-pencil icon_style"></i>}
                                                labelText="Robots Meta"
                                                req={false}
                                                name="robots_meta"
                                                placeText="Robots Meta"
                                            />
                                            <BaseInput
                                                inputIcon={<i className="bi bi-pencil icon_style"></i>}
                                                labelText="Meta Author"
                                                req={false}
                                                name="meta_author"
                                                placeText="Meta Author"
                                            />
                                            <BaseInput
                                                inputIcon={<i className="bi bi-pencil icon_style"></i>}
                                                labelText="Og Type"
                                                req={false}
                                                name="og_type"
                                                placeText="Og Type"
                                            />
                                            <BaseInput
                                                inputIcon={<i className="bi bi-pencil icon_style"></i>}
                                                labelText="Og Title"
                                                req={false}
                                                name="og_title"
                                                placeText="Og Title"
                                            />
                                            <TextAreas
                                                labelText="Og Description"
                                                req={false}
                                                name="og_description"
                                                placeText="Og Description"
                                                maxLength={160}
                                            />
                                            <BaseInput
                                                inputIcon={<i className="bi bi-pencil icon_style"></i>}
                                                labelText="Og Image Width"
                                                req={false}
                                                name="og_image_width"
                                                placeText="Og Image Width"
                                            />
                                            <BaseInput
                                                inputIcon={<i className="bi bi-pencil icon_style"></i>}
                                                labelText="Og Image height"
                                                req={false}
                                                name="og_image_height"
                                                placeText="Og Image height"
                                            />
                                            <BaseInput
                                                inputIcon={<i className="bi bi-pencil icon_style"></i>}
                                                labelText="Og Url"
                                                // isUrl={true}
                                                req={false}
                                                name="og_url"
                                                placeText="Og Url"
                                            />
                                            <BaseInput
                                                inputIcon={<i className="bi bi-pencil icon_style"></i>}
                                                labelText="Og Site Name"
                                                req={false}
                                                name="og_site_name"
                                                placeText="Og Site Name"
                                            />
                                            <BaseInput
                                                inputIcon={<i className="bi bi-pencil icon_style"></i>}
                                                labelText="Og Locale"
                                                req={false}
                                                name="og_locale"
                                                placeText="Og Locale"
                                            />
                                            <BaseInput
                                                inputIcon={<i className="bi bi-pencil icon_style"></i>}
                                                labelText="Og Locale Alternate"
                                                req={false}
                                                name="og_locale_alternate"
                                                placeText="Og Locale Alternate"
                                            />
                                            <BaseInput
                                                inputIcon={<i className="bi bi-pencil icon_style"></i>}
                                                labelText="Twitter Card"
                                                req={false}
                                                name="twitter_card"
                                                placeText="Twitter Card"
                                            />
                                            <BaseInput
                                                inputIcon={<i className="bi bi-pencil icon_style"></i>}
                                                labelText="Twitter Site"
                                                req={false}
                                                name="twitter_site"
                                                placeText="Twitter Site"
                                            />
                                            <BaseInput
                                                inputIcon={<i className="bi bi-pencil icon_style"></i>}
                                                labelText="Twitter Title"
                                                req={false}
                                                name="twitter_title"
                                                placeText="Twitter Title"
                                            />
                                            <TextAreas
                                                labelText="Twitter Description"
                                                req={false}
                                                name="twitter_description"
                                                placeText="Twitter Description"
                                                maxLength={160}
                                            />
                                            <BaseInput
                                                inputIcon={<i className="bi bi-pencil icon_style"></i>}
                                                labelText="Twitter Url"
                                                // isUrl={true}
                                                req={false}
                                                name="twitter_url"
                                                placeText="Twitter Url"
                                            />
                                            <BannerImageCard
                                                title="OG Image"
                                                imageUrl={ogImageUrl ?? BannerImage}
                                                isBlogImage={true}
                                                setImageFile={setOGImageFile}
                                                setImageUrl={setOGImageUrl}
                                                imageText="Change Image"
                                            />
                                            <BannerImageCard
                                                title="Twitter Image"
                                                isBlogImage={true}
                                                imageUrl={twitterImageUrl ?? BannerImage}
                                                setImageFile={setTwitterImageFile}
                                                setImageUrl={setTwitterImageUrl}
                                                imageText="Change Image"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default SeoSection;
