import React, { useEffect, useState } from "react";

import {
  Row,
  Col,
  Button,
  Dropdown,
} from "antd";
import logo from "../../assets/images/logo.png";
import logoutImage from "../../assets/images/logoutButton.png";
import { useNavigate } from "react-router-dom";
import { successNotification } from "../notifications/alertNotification";
import profile from "../../assets/images/profile.png"
import { setLogout } from "../../redux/authSlice";
import { useDispatch } from "react-redux";

const toggler = [
  <svg
    width="20"
    height="20"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 448 512"
    key={0}
  >
    <path d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z"></path>
  </svg>,
];

function Header({ placement, name, subName, onPress, }) {

  const dispatch = useDispatch();
  const handleLogout = () => {
    // localStorage.removeItem("user");
    dispatch(setLogout());
    successNotification("Successfully Logged Out!");
    navigate("/login");
    // Other logout logic...
  };

  const items = [
    {
      key: '1',
      label: (
        <span className="px-3" onClick={handleLogout}>Logout</span>
      ),
    },

  ];


  useEffect(() => window.scrollTo(0, 0));
  const navigate = useNavigate();

  return (
    <>
      <Row gutter={[24, 0]}>
        <Col span={24} md={6} xs={12}>
          <div className="brand">
            <a href="/">
              <img src={logo} alt="" style={{ height: '35px' }} />
            </a>
          </div>
        </Col>
        <Col span={24} md={18} xs={12} className="header-control">
          <Button
            type="link"
            className="sidebar-toggler"
            onClick={() => onPress()}
          >
            {toggler}
          </Button>



          <div className="profile-dropdown">
            {/* <button className="dropdown-toggle" type="button" onClick={toggleDropdown}>

            </button> */}

            {/* <ul className={`dropdown-menu ${isDropdownOpen ? 'open' : 'close'}`}>
              <li className="px-3 " onClick={handleLogout}>LogOut</li>
            </ul> */}
          </div>

          <div className="cursor-pointer d-flex align-items-center text-white gap-2">
            <img src={profile} alt="" className="img-fluid" style={{ width: '30px', borderRadius: '50%' }} />

            <Dropdown
              trigger={["click"]}
              menu={{
                items
              }}
              arrow={{
                pointAtCenter: true,
              }}

            >
              <i className="bi bi-chevron-down text-white pt-2" style={{ cursor: "pointer" }}></i>
            </Dropdown>
          </div>

        </Col>
      </Row>
    </>
  );
}

export default Header;
