import React from "react";
import { Button } from "antd";
import { STATUSES } from "../../config/constants/defaultValues";

const SaveButton = ({ title, loading, disabled }) => {
  let isLoading = loading === STATUSES.LOADING ? true : false;
  return (
    <>
      <Button
        type="button"
        style={{ fontWeight: "500 !important" }}
        className="approved_btn"
        htmlType="submit"
        loading={isLoading}
        disabled={isLoading || disabled}
      >
        {title}
      </Button>
    </>
  );
};

export default SaveButton;
