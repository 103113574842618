import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  errorNotification,
  successNotification,
} from "../components/notifications/alertNotification";
import {
  REQUEST_METHOD,
  STATUSES,
  USER_TYPE,
} from "../config/constants/defaultValues";
import { LOGIN_API } from "../config/constants/endPoints";
import { apiService } from "../config/services/api.service";

export const loginRequest = createAsyncThunk(
  "/login",
  async ({ values }, { rejectWithValue }) => {
    try {
      const userTypeArray = Object.values(USER_TYPE);

      const response = await apiService(
        LOGIN_API,
        REQUEST_METHOD.POST,
        values,
        false
      );
      if (response.status === 200) {
        if (
          userTypeArray.length > 0 &&
          userTypeArray.includes(response.data.data.roles[0].id)
        ) {
          successNotification("Login Successful!");
          return response.data.data;
        } else {
          return errorNotification(
            "Enter your admin username and password here."
          );
        }
      } else {
        return errorNotification("Invalid credentials. Please try again");
      }
    } catch (error) {
      errorNotification("Something went wrong");
      return rejectWithValue("Something went wrong");
    }
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState: {
    userData: JSON.parse(localStorage.getItem("user")),
    status: STATUSES.IDLE,
    userType: JSON.parse(localStorage.getItem("user"))?.roles[0].id,
    error: null,
  },
  reducers: {
    setUser: (state, action) => {
      state.userData = action.payload;
    },
    setLogout: (state, action) => {
      localStorage.removeItem("user");
      localStorage.clear();
      state.userData = null;
    },
  },
  extraReducers: {
    [loginRequest.pending]: (state, action) => {
      state.status = STATUSES.LOADING;
    },
    [loginRequest.fulfilled]: (state, action) => {
      state.status = STATUSES.IDLE;
      if (action.payload) {
        localStorage.setItem("user", JSON.stringify(action.payload));
        state.userType = action.payload?.roles[0].id;
        state.userData = action.payload;
      }
    },
    [loginRequest.rejected]: (state, action) => {
      state.status = STATUSES.ERROR;
      state.error = action.payload;
    },
  },
});
export default authSlice.reducer;
export const { setUser, setLogout } = authSlice.actions;
