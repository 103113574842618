// export const SERVER_URL = "http://192.168.18.23:86/api";

// import UserList from "../../pages/Users/userList";
// import AutoCompleteRequest from "../../pages/autoCompleteRequest";
// import ContactFormList from "../../pages/contactForm/contactFormList";
// import ContentManagement from "../../pages/contentManagement/content-management";
// import EmailContactFormList from "../../pages/emailContact/emailContactForm";
// import JobAppliedList from "../../pages/jobApplied/jobAppliedList";
// import JobCreate from "../../pages/jobs/JobCreate";
// import JobList from "../../pages/jobs/JobList";
// import JobRequestDetail from "../../pages/jobs/jobDetail";
// import JobEdit from "../../pages/jobs/jobEdit";
// import ReferralList from "../../pages/referral/referralList";
// import SystemConfiguration from "../../pages/systemConfiguration/systemConfiguration";

export const SERVER_URL = "https://live.imperiallocum.com/api";
// export const SERVER_URL = "https://dev.imperiallocum.com/api";
export const STATUSES = Object.freeze({
  IDLE: "idle",
  LOADING: "loading",
  ERROR: "error",
  LOADED: "error",
});
export const BannerImage = require("../../assets/images/bannerImage.png");

// export const USER_TYPE = Object.freeze({
//   SUPER_ADMIN: "1",
//   CONTENT_ADMIN: "3",
//   JOB_ADMIN: "4",
// });

export const USER_TYPE = Object.freeze({
  SUPER_ADMIN: 1,
  CONTENT_ADMIN: 3,
  JOB_ADMIN: 4,
  // ===============
  SYSTEM_ADMIN: 0,
  JUMP_ADMIN: 5,
  BLOG_ADMIN: 6,
  NONE: 0,
});

export const REQUEST_METHOD = Object.freeze({
  GET: "GET",
  POST: "POST",
  PUT: "PUT",
  PATCH: "PATCH",
  DELETE: "DELETE",
});
export const CONTENT_PAGES = Object.freeze({
  HOME_PAGE: 1,
  WHAT_LOCUM_TENENS_PAGE: 2,
  WHY_WORK_LOCUM_TENENS_PAGE: 3,
  LOCUM_TENENS_PAGE: 4,
  IMPORTANCE_LOCUM_TENENS_PAGE: 5,
  ABOUT_US_PAGE: 6,
  JOB_LIST: 7,
  CONTACT_US_PAGE: 8,
  LOGIN_PAGE: 9,
  REGISTER_PAGE: 10,
  FORGOT_PASSWORD_PAGE: 11,
  RESET_PASSWORD_PAGE: 12,
  SUCCESS_RESET_PASSWORD_PAGE: 13,
  TERMS_PAGE: 14,
  PRIVACY_PAGE: 15,
  COOKIE_POLICY_PAGE: 16,
  WEBSITE_FOOTER: 17,
});
export const TableDataState = Object.freeze({
  CREATE: 1,
  UPDATE: 2,
  DELETE: 3,
});
export const ImageUploadDefaults = Object.freeze({
  MAX_SIZE: 2097152, // 2MB
  ALLOWED_EXTENSIONS: ".png, .jpg, .jpeg, .svg, .webp",
});
// export const ROLE_BASED_ROUTES = {
//   SUPER_ADMIN: [
//     { id: 1, path: "/job-list", isProtected: true, child: <JobList /> },
//     {
//       id: 2,
//       path: "/job-list/Job-details/:jobID",
//       isProtected: true,
//       child: <JobRequestDetail />,
//     },
//     {
//       id: 3,
//       path: "/auto-complete",
//       isProtected: true,
//       child: <AutoCompleteRequest />,
//     },
//     { id: 4, path: "/job-create", isProtected: true, child: <JobCreate /> },
//     {
//       id: 5,
//       path: "/job-list/job-edit/:jobID",
//       isProtected: true,
//       child: <JobEdit />,
//     },
//     {
//       id: 6,
//       path: "/job-applications",
//       isProtected: true,
//       child: <JobAppliedList />,
//     },
//     { id: 7, path: "/user-list", isProtected: true, child: <UserList /> },
//     {
//       id: 8,
//       path: "/referral-list",
//       isProtected: true,
//       child: <ReferralList />,
//     },
//     {
//       id: 9,
//       path: "/contact-form-list",
//       isProtected: true,
//       child: <ContactFormList />,
//     },
//     {
//       id: 10,
//       path: "/email-contact-list",
//       isProtected: true,
//       child: <EmailContactFormList />,
//     },
//   ],
//   JOB_ADMIN: [
//     {
//       id: 1,
//       path: "/job-applications",
//       isProtected: true,
//       child: <JobAppliedList />,
//     },
//     {
//       id: 2,
//       path: "/referral-list",
//       isProtected: true,
//       child: <ReferralList />,
//     },
//     {
//       id: 3,
//       path: "/contact-form-list",
//       isProtected: true,
//       child: <ContactFormList />,
//     },
//     {
//       id: 4,
//       path: "/email-contact-list",
//       isProtected: true,
//       child: <EmailContactFormList />,
//     },
//   ],
//   CONTENT_ADMIN: [
//     {
//       id: 1,
//       path: "/content-management",
//       isProtected: true,
//       child: <ContentManagement />,
//     },
//     {
//       id: 2,
//       path: "/system-configuration",
//       isProtected: true,
//       child: <SystemConfiguration />,
//     },
//   ],
// };
