import { Modal } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import profile from "../../assets/images/profile.png";
import { getJobDetailsByIdRequest } from "../../redux/jobsSlice";

const ContactFormListViewModal = ({
  isModalOpen,
  setIsModalOpen,
  modalData = null,
  setModelData = undefined,
}) => {
  const handleCancel = () => {
    setModelData(null);
    setIsModalOpen(false);
  };
  console.log("modalData", modalData);
  const dispatch = useDispatch();
  //   const navigate = useNavigate();
  //   const { jobID } = useParams();

  const { status, jobDetails } = useSelector((state) => state.jobsList);
  const Mailto = ({ email, subject = "", body = "", children }) => {
    let params = subject || body ? "?" : "";
    if (subject) params += `subject=${encodeURIComponent(subject)}`;
    if (body) params += `${subject ? "&" : ""}body=${encodeURIComponent(body)}`;

    return (
      <a className="" href={`mailto:${email}${params}`}>
        {children}
      </a>
    );
  };
  useEffect(() => {
    if (modalData?.jobID) {
      dispatch(getJobDetailsByIdRequest({ jobID: modalData?.jobID }));
    }
  }, [dispatch, modalData]);
  return (
    <>
      <Modal
        className="modal-styles"
        open={isModalOpen}
        onCancel={handleCancel}
        footer={null}
        // width={600}
        maskClosable={false}
        centered={true}
        title={false}
      >
        <div className="row">
          <div className="col-md-12">
            <h5 className="table-heading mb-0 ">Contact form Details</h5>
          </div>
        </div>
        <div className="modal-content">
          <div className="row">
            <div className="col-lg-5">
              <div className="d-flex align-items-center user-profile">
                <div>
                  <img src={profile} alt="" className="img-fluid" />
                </div>
                <div className="ms-3">
                  <h3 className="mb-0">
                    {" "}
                    {modalData?.first_name ?? ""}&nbsp;
                    {modalData?.last_name ?? ""}
                  </h3>
                  <p className="mb-0">{modalData?.discipline?.name ?? ""}</p>
                </div>
              </div>
            </div>
            <div className="col-lg-7 ">
              <div className="Applied-box mt-3 mt-lg-0 d-sm-flex align-items-center justify-content-end shadow-none border-0">
                <div>
                  <Mailto email={modalData?.email} subject="" body="">
                    <button className="applied-btn mt-2 mt-sm-0">Reply Mail</button>
                  </Mailto>
                </div>
              </div>
            </div>
            <div className="mt-3">
              <div className="d-sm-flex ">
                <div className="col-sm-6">
                  <div className="user-data">
                    <h6>Email Address</h6>
                    <p>{modalData?.email ?? ""}</p>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="user-data">
                    <h6>Phone Number</h6>
                    <p>{modalData?.phone ?? ""}</p>
                  </div>
                </div>
              </div>
              <div className="d-sm-flex ">
                <div className="col-sm-6">
                  <div className="user-data">
                    <h6>Discipline</h6>
                    <p>{modalData?.discipline?.name ?? ""}</p>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="user-data">
                    <h6>Contact Date</h6>
                    <p>{modalData?.created_at ?? ""}</p>
                  </div>
                </div>
              </div>
              <div className="d-sm-flex ">
                <div className="col-sm-12 ">
                  <div className="user-data">
                    <h6>Message</h6>
                    <p className="border rounded-2 p-4">{modalData?.message ?? ""}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ContactFormListViewModal;
